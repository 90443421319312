import React, { Component,Fragment } from 'react'
import axios from 'axios';
import Prodetails from './prodetails'
import API from '../../../services';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default class Tabel extends Component {

    state = { 
            sku:'',
            name:'',
            qty:1,
            price:0,
            discount:0,
            total:0,
            image:"https://pngimage.net/wp-content/uploads/2018/06/none-png-2.png",
            load:false,
            modal:false
   }

    componentDidMount() {
        // console.log(this.state.proDetails.image);
    }

    details = event => {
        this.setState({ load:false });
        axios.get(localStorage.getItem('url')+'product/get/'+event.target.id,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            this.setState({ 
                name: response.data.content.name, sku:response.data.content.sku,
                color:response.data.content.color, price:response.data.content.price,
                image:response.data.content.image, total:parseInt(this.state.qty*response.data.content.price),
                load:true,modal:true
             }); 
        })
        .catch((err) => { API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);  console.log("Error get_product details : ", err); API.otentikasi_acl('product details'); }); 
    }

     handleClose = () =>{
        this.setState({
            modal:false,load:false
        })
    }
    handleShow = () =>{
        this.setState({
            modal:true,load:true
        })
    }

    render() {
        return <Fragment>

        <div className="col-md-12 col-xs-12 no-pad table-responsive">
        <table id="myTable" className="table tableborder" border="1" width="100%">
        <thead>
              <tr className="header">
                <th>SKU</th>
                <th>Model</th>
                <th>Qty</th>
                <th>Price</th>
                <th>#</th>
              </tr>
        </thead>
        <tbody>          
        {
            this.props.data.map((dynamicData,index) => {
                return (
                <Fragment key={index}>
                <tr>
                    <td>{dynamicData.sku}</td>
                    <td>{dynamicData.name.toUpperCase()} <br></br> {dynamicData.category} -<br></br>{dynamicData.brand}</td>
                    <td>{dynamicData.qty}</td>
                    <td>{API.formatNumber(dynamicData.price)}</td>
                    <td> 
                    <button id={dynamicData.id} onClick={this.details} className="btn btn-primary btn-xs">[...]</button>
                    </td>
                </tr>
                </Fragment>                
                );
            })

        }
                </tbody>    
                </table>  
        </div>

        {/* <Prodetails load_data={this.props.load_data} data={this.state}></Prodetails> */}
        <Modal show={this.state.modal} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title> Product Detail - {this.state.sku} </Modal.Title> 
            </Modal.Header> 
            <Modal.Body>
                <Prodetails user={this.props.user} handle_close={this.handleClose} load_data={this.props.load_data} data={this.state}></Prodetails>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}> Close </Button>
            {/* <Button variant="secondary" onClick={window.print()}> Print </Button> */}
            </Modal.Footer>
        </Modal>
        
        </Fragment>
        
       } 
}