import React, { Component,Fragment } from 'react'
import axios from 'axios';
import API from '../../../services/index';

export default class Tabel extends Component {

//     state = { 
//             name:'None',
//             category:'None',
//             brand:'None',
//             color:'None',
//             qty:0,
//             price:0,
//             image:null,
//             load:false
//    }

    componentDidMount() {
        // console.log(this.state.proDetails.image);
    }

    details = event => {
        // console.log(event.target.id);
        axios.get(localStorage.getItem('url')+'product/details/'+event.target.id,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 

            // console.log(response.data.content);
            this.setState({ 
                name: response.data.content.name, category:response.data.content.category, brand:response.data.content.brand,
                color:response.data.content.color, qty:response.data.content.qty, price:response.data.content.price,
                image:response.data.content.image, load:true
             }); 
        })
        .catch((err) => { console.log("Error texthandle : ", err); API.otentikasi_acl('product search'); }); 
    }

    render() {
        if (this.props.load === true){ 
        return <Fragment>
        <div className="col-md-9 col-xs-12 no-pad table-responsive">
        <table id="myTable" className="table table-striped table-bordered"> 
        <thead>
              <tr className="header">
                <th>Modul</th>
                <th>Date</th>
                <th>Time</th>
                <th>Code</th>
                <th>Error</th>
              </tr>
        </thead>
        <tbody>          
        {
            this.props.data.map((dynamicData,index) => {
                return (
                <Fragment key={index}>
                <tr>
                    <td>{dynamicData.modul}</td>
                    <td>{API.dateformat(dynamicData.date,'-')}</td>
                    <td>{dynamicData.time}</td>
                    <td>{dynamicData.code}</td>
                    <td>{dynamicData.error}</td>
                </tr>
                </Fragment>                
                );
            })
        }
        </tbody>    
        </table>  
        </div>   
        </Fragment>
        }else{ return (<Fragment> </Fragment>) }
        
       } 
}