import React, { Component,Fragment } from 'react'
import API from '../../../services';
// import swal from 'sweetalert';
// import $ from 'jquery';
import db from '../../../services/db';
import axios from 'axios';

export default class Prodetailsupdate extends Component {

    state = { data:[],total:0, id:'',tax:0, taxval:0 }
    total = 0;

    componentDidMount() {
       this.tax(); 
    }

    tax = () => {
        API.tax().then(result => {
            this.setState({ data: result.content.result });
        }).catch((err) => {
            console.log("Error get_tax : ", err); API.otentikasi_acl('tax');
        })
    }

    calculate = (event) => {
        // console.log(event.target.value);
        if (event.target.value){
            this.setState({ id:this.props.data.id });
            let discount = document.getElementById("tdiscount_update").value;
            let qty = document.getElementById("tqty_update").value;
            let tax = document.getElementById("ctax_update").value;

            let hasil = parseInt(this.props.data.price*qty);
            if (hasil >= discount){ 
                let taxres = parseInt(tax*hasil);
                this.setState({ total:parseInt(hasil-discount+taxres), tax:taxres, taxval:tax });  
            }
            else{ let taxres = parseInt(tax*hasil); document.getElementById("tdiscount_update").value = 0; this.setState({ total:hasil+taxres, tax:taxres, taxval:tax }); }
        }
    }

    cek_sku_api = async(sku,qty=1)=>{
        return axios.get(localStorage.getItem('url')+'pos/valid_qty/'+sku+'/'+qty,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { return true; })
        .catch((err) => { return false; }); 
    }

    post_data = async() => {
        let discount = document.getElementById("tdiscount_update").value;
        let qty = document.getElementById("tqty_update").value;
        let total = 0, tax = 0;

        if (this.props.data.id !== this.state.id){ total = this.props.data.total;}
        else{ total = this.state.total; tax = this.state.tax; }

        if (qty < 1){ db.posts.delete(this.props.data.id).then(async()=>{ this.props.load_data();})
        }else{
            let valid_api = await this.cek_sku_api(this.props.data.sku,qty);
            if (valid_api === true){
                // edit data to database
                let postdata = {
                    id:this.props.data.id, sku: this.props.data.sku, name: this.props.data.name.toUpperCase(), qty: qty, user:this.props.data.user, price:this.props.data.price, discount:discount, tax:tax, taxval:this.state.taxval, total:total
                }
                // console.log(postdata);
                db.posts.put(postdata).then(async()=>{
                // console.log('berhasil ubah data');
                    API.SwalTimer('item succesfull updated',this.props.data.sku,'success',900);
                    this.props.load_data();
                }) 
            }else{ API.SwalTimer('Invalid Request Qty',this.props.data.sku,'warning',800); }
    
        } 
        this.props.set_load_false();
    }

    render() {

        if (this.props.data.load === true){

            if (this.props.data.id !== this.state.id){ this.total = this.props.data.total;}
            else{ this.total = this.state.total; }

            return( 
                <Fragment>    
                <table className="table">
                <tbody>
                    <tr><td><label> SKU </label></td><td>{this.props.data.sku}</td></tr>
                    <tr><td><label> Name </label></td><td>{this.props.data.name.toUpperCase()}</td></tr>
                    <tr><td><label> Qty </label></td><td> <input type="number" id="tqty_update" className="tqty" defaultValue={this.props.data.qty} onChange={this.calculate}></input> </td></tr>
                    <tr><td><label> Price </label></td><td>{API.formatNumber(this.props.data.price)},-</td></tr>
                    <tr><td><label> Discount </label></td><td> <input type="number" id="tdiscount_update" className="tdiscount" defaultValue={this.props.data.discount} onChange={this.calculate}></input> </td></tr>
                    <tr><td><label> Tax </label></td>
                    <td>
                    <select defaultValue={parseFloat(this.props.data.taxval)} name="ctax" id="ctax_update" className="slc slc2" onChange={this.calculate}>
                    {
                        this.state.data.map((dynamicData,index) => {
                            return (
                                <Fragment key={index}>
                                    <option value={dynamicData.value}>{API.capitalize(dynamicData.code)}</option>
                                </Fragment>                
                            ); 
                        })
                    }
                    </select>
                    </td></tr>
                    <tr><td><label> Total </label></td><td>{ API.formatNumber(this.total)},- </td></tr>
                    <tr><td></td><td> <button className="btn btn-primary" onClick={this.post_data}> POST </button> </td></tr>
                </tbody>    
                </table> <br></br>
                <hr></hr>
                </Fragment>
            )
        }else{ return ( <Fragment></Fragment> ) }   
       } 
}