import React, { Component,Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
// import swal from 'sweetalert';
import API from './../../services';
import db from '../../services/db';
import {connect} from 'react-redux';
// import ActionType from '../../redux/reducer/globalActionType';

class Home extends Component {

    constructor(props){
        super(props);
        this.state = { proname:""}
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    get_property = () =>{
        API.property().then(result => {
            // console.log(result.content.name);
            this.setState({proname:result.content.name});
        }).catch((err) => {
            // swal("Request Error","","error");
            API.otentikasi_acl('property'); 
            API.post_error_db(this.constructor.name.toLowerCase(),err);
            // console.log("ini error : ", err.data.error);
        }) 
    }

    token_otentikasi = async() => { 
       const stts = await API.otentikasi();
       if (stts === true){ this.get_property(); this.props.set_true(); }else if(stts === false){ this.props.set_false(); }
    }

    logout = () => {
       API.logout_service();
       this.props.set_false();
    }

    render() {

    // console.log(' Ini nilai baru :',this.props.datatrans);  
    //   console.log('State login adalah :',this.props.loggedin);
       if (this.props.loggedin === false){ return <Redirect to='/login'></Redirect>}
        return (
            <Fragment>

<h3 className="head1"> {this.state.proname} </h3>
<div className="row mb">
    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
        <button id="addnew">
        <img src="../../newicons/setup.png" alt="" className="img-responsive"></img>
        <span> Setup </span>
        </button>
    </div>

    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
    <Link to="/contact">
        <button id="addnew">
        <img src="../../newicons/kontak.png" alt="" className="img-responsive"></img>
        <span> Kontak </span>
        </button>
    </Link>
    </div>

    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
    <Link to="/product">
        <button id="addnew">
        <img src="../../newicons/produk.png" alt="" className="img-responsive"></img>
        <span> Product </span>
        </button>
    </Link>
    </div>

    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
        <Link to="/pos">
        <button id="addnew">
        <img src="../../newicons/penjualan.png" alt="" className="img-responsive"></img>
        <span> Transaksi </span>
        </button>
        </Link>
    </div>
</div>
{/*  kolom bawah  */}

<div className="row mb">
    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
        <Link to="/transaction">
        <button id="addnew">
        <img src="../../newicons/data-transaksi.png" alt="" className="img-responsive"></img>
        <span> Data Transaksi </span>
        </button>
        </Link>
    </div>

    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
        <button id="addnew">
        <img src="../../newicons/laporan.png" alt="" className="img-responsive"></img>
        <span> Laporan / Statistik </span>
        </button>
    </div>

    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
       <Link to="/log">
        <button id="addnew">
        <img src="../../newicons/posting.png" alt="" className="img-responsive"></img>
        <span> Error Log </span>
        </button>
       </Link>
    </div>

    <div className="col-md-3 col-xs-6 col-sm-3 mobile3">
        <button id="addnew" onClick={this.logout}>
        <img src="../../newicons/logout2.png" alt="" className="img-responsive"></img>
         <span> Logout </span> 
        </button>
    </div>
    
</div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedin : state.Loggedin,
        datatrans : state.Datatrans
    }
}

const mapDispatchToProps = (dispatch) => {
   return {
       set_true : () => dispatch({type: 'SET_TRUE'}),
       set_false : () => dispatch({type: 'SET_FALSE'})
   }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home);
// export default Home;