import React, { Component,Fragment } from 'react'
import axios from 'axios';
import { Redirect,Link } from 'react-router-dom';
import swal from 'sweetalert';
import {connect} from 'react-redux';
import API from '../../services';
// import ActionType from '../../redux/reducer/globalActionType';
import Loading from '../../loading';
import $ from 'jquery';

class Login extends Component {

    constructor(props){
        super(props)
        this.state = { seturl:true, load:true, userid:null, username:null}
        localStorage.removeItem('token');
    }

    componentDidMount(){
        $("#username").focus();
        if (localStorage.getItem('url') === null){ this.setState({seturl:false}); }
    }

    handleInput=(e)=>{
        if (e.key === 'Enter'){ this.login(); }
    }

    login = async() =>{
        
      let user = document.getElementById("username").value;
      let pass = document.getElementById("password").value;

      if (user !== "" && pass !== ""){
          this.setState({ load:false })
          axios.post(localStorage.getItem('url')+'login',{user: user,pass: pass})
            .then((response) => {
                // console.log("Ini response : ",response);
                // console.log(response.data.token); 
                localStorage.setItem('token', response.data.token);
                this.props.set_true();
            })
            .catch((error) => { 
                // error.response.status
                API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
                swal(error.response.data.error, "", "error");
                // console.log(error);
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ swal('username & password required','','error'); $("#username").focus(); }
    }

    render() {
        if (this.state.seturl === false){ return <Redirect to='/otp'></Redirect>}
        if (this.props.loggedin === true){ return <Redirect to='/'></Redirect>}

        if (this.state.load === true){
            return (
                <Fragment>
                <div className="thebox">
                <h1 className="head1"> Login </h1>
                    <form action="">
                            <label>Username</label>
                            <input type="text" name="username" id="username" className="form-control" onKeyUp={this.handleInput}></input> <br></br>
                            <label>Password</label>
                            <input type="password" className="form-control" id="password" onKeyUp={this.handleInput}></input> <br></br>
                            <button type="button" className="btn loginbtn" onClick={this.login}> Login </button>
                            <Link to='/otp'> <button type="button" className="btn loginbtn"> Back OTP </button> </Link>
                    </form>
                </div>
                </Fragment>
            )
        }else{ return (<Loading></Loading>) }
    }
}

const mapStateToProps = (state) => {
    return {
        loggedin : state.Loggedin,
        datatrans : state.Datatrans
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_true : () => dispatch({type: 'SET_TRUE'}),
        set_false : () => dispatch({type: 'SET_FALSE'})
    }
 }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         set_true : () => dispatch({type: ActionType.SET_TRUE}),
//         set_false : () => dispatch({type: ActionType.SET_FALSE})
//     }
//  }

export default connect(mapStateToProps,mapDispatchToProps)(Login); 
