import React, { Component,Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
// import swal from 'sweetalert';
import API from '../../services';
import {connect} from 'react-redux';
// import ActionType from '../../redux/reducer/globalActionType';
import $ from 'jquery';
import axios from 'axios';
import Loading from '../../loading';
import Combopayment from '../sales/comp/combo_payment';
import Datepicker from '../../services/Datepicker';
import Chart from '../../services/Chart';

class Translist extends Component {

    state = { data:[], bruto_tot:0,load:true, userid:0, username:'',rules:0,log:0,
              labels:[], totchart:[], showtrans:false, charttitle: 'Total Summary'
            }
    time = null; date = null;
    today = new Date();
    child = null

    search= async() => {
        let date = $("#startDate").val();
        this.setState({load:false });
        let payment = $('#cpayment').val();

        let param = null;
        if (this.state.rules !== '3'){ param = {payment:payment,date:API.dateformat(date),user:this.state.userid}; }
        else{ param = {payment:payment,date:API.dateformat(date),user:""}; }
        $('#myTable').dataTable().fnClearTable();
        axios.post(localStorage.getItem('url')+'pos',param,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            if (response.data.content.result != null){
                this.setState({ data: response.data.content.result,load:true }); 
                $('#myTable').DataTable(); 
            }else{
                this.setState({ load:true }); 
                $('#myTable').dataTable().fnClearTable();
            }
        }).catch((err) => { API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); console.log("Error pos : ", err); API.otentikasi_acl('pos'); });
    }

    load_data_trans = async() => {

        this.setState({load:false });
        let param = null;
        if (this.state.rules !== '3'){ param = {payment:"",date:API.today(),user:this.state.userid};}
        else{ param = {payment:"",date:API.today(),user:""}; }
        
        axios.post(localStorage.getItem('url')+'pos',param,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            if (response.data.content.result != null){
                this.setState({ data: response.data.content.result,load:true }); 
                $('#myTable').DataTable(); 
            }else{
                this.setState({ load:true }); 
                $('#myTable').dataTable().fnClearTable();
            }
        }).catch((err) => { API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); console.log("Error pos : ", err); API.otentikasi_acl('pos'); }); 
    };

    componentDidMount(){
        $("#tsearch").focus();
        this.token_otentikasi();
        this.chart();
        this.showhide();
    }

    chart = async()=>{
        let param = $("#ctype").val();
        let url = null;
        if (param === '2'){url = localStorage.getItem('url')+'pos/chartavg';}
        else if (param === '3'){url = localStorage.getItem('url')+'pos/chartavg/1';}
        else{ url = localStorage.getItem('url')+'pos/chart/'+param; }

         axios.get(url,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
         .then((response) => { 
            let jsondata = response.data.content.result; let result = []; let result1 = [];
            for(var i in jsondata){ result.push(jsondata[i].label); result1.push(jsondata[i].data); }
            this.setState({ labels:result, totchart:result1, charttitle:'Total Summary' })
         })
         .catch((err) => { API.post_error_db(this.constructor.name.toLowerCase(),'error chart pos',err.response.status); console.log('error chart : ',err) }); 
    }

    decode = async() => {
       const decode = await API.decode();
       this.setState({ userid: decode.userid,username:decode.username,rules:decode.rules,log:decode.log},() => { this.load_data_trans(); });
    }

    token_otentikasi = async() => { 
        const stts = await API.otentikasi();
        if (stts === true){ this.decode(); this.props.set_true(); }else if(stts === false){ this.props.set_false(); }
    }

    reset = () => {
        $("#tsearch").val(""); $("#tsearch").focus();
        this.load_data_trans();
    }

    componentWillUnmount(){ return true; }

     showhide=()=>{
        if (this.state.showtrans === true){ this.setState({showtrans: false }, () => {$("#boxchart").fadeOut();}) }
        else if(this.state.showtrans === false){ this.setState({showtrans: true }, () => {$("#boxchart").fadeIn();}) }
    }

    prints=(event)=>{
        let orderid = event.target.id;
        let myWindow = window.open(localStorage.getItem('url')+'pos/invoice/'+orderid+'/invoice', "POS - Invoice", "width=500,height=600");
    }

    delete=(event)=>{

        axios.get(localStorage.getItem('url')+'pos/delete/'+event.target.id,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            // console.log(response.data.content);
            API.SwalTimer(response.data.content,'','success',2000);
            this.reset();
        })
        .catch((err) => { API.post_error_db(this.constructor.name.toLowerCase(),"Error delete pos transaction : ",err.response.status);  console.log("Error delete pos transaction : ", err); API.otentikasi_acl('pos delete'); }); 
    }

    searchsku=async(event)=>{
        if (event.target.value){
            let sku = parseInt(event.target.value);
            let name = null;
            let status = await axios.get(localStorage.getItem('url')+'product/valids_sku/'+sku,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
            .then((response) => { name = response.data.content.name; return true; }).catch((err) => { return false; }); 
                
            if (status === true){
                axios.get(localStorage.getItem('url')+'pos/chartavg/2/'+sku,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
                .then((response) => { 
                    let jsondata = response.data.content.result; let result = []; let result1 = [];
                    for(var i in jsondata){ result.push(jsondata[i].label); result1.push(jsondata[i].data); }
                    this.setState({ labels:result, totchart:result1, charttitle:'Summary : '+sku+' - '+name })
                })
                .catch((err) => {  console.log('error chart : ',err) }); 
            } 
        }    
    }

    render() {
        const { load } = this.state;
        if (this.props.loggedin === false){ return <Redirect to='/login'></Redirect>}
        if (load === true){
            return (
                <Fragment>
    
<h3 className="head1"> Transaction List - {API.capitalize(this.state.username)} </h3> <br></br>
<div className="row">
       <div className="col-md-6 col-xs-12">
      		<table style={{width:"300px"}}>
              <tbody>
      			<tr>
      				<td> <select id="ctype" className="form-control" onChange={this.chart}>
                            <option value='0'> Monthly </option>
                            <option value='1'> Annually </option>
                            <option value='2'> Best Selling Montlhy </option>
                            <option value='3'> Best Selling Annually </option>
                         </select> 
                    </td>
                    <td>
                        <input type="text" className="form-control" placeholder="SKU..." onKeyUp={this.searchsku} />
                    </td>
                    <td> <button type="button" className="btn btn-dark" onClick={this.showhide}> Show/Hide Chart </button>  </td>
      			</tr>
                </tbody>
      		</table>
       </div>
</div> <br></br>

<div className="row">
    <div className="col-md-12 col-xs-12" id="boxchart">
       <Chart title={this.state.charttitle} labels={this.state.labels} data={this.state.totchart} />
    </div>
</div>

<div className="row">

      <div className="col-md-7 col-xs-12 coltable1">
       <br></br>
        <table>
        <tbody>
        <tr>
        <td> <Combopayment class="form-control"></Combopayment> </td>
        <td> 
         <Datepicker/>
        </td>
        {/* <td> <input type="text" id="tsearch" placeholder="Search.." className="form-control"></input> </td>  */}
        <td>
        <div className="btn-group">
            <button type="button" className="btn btn-primary" onClick={this.search}> Search </button>
            <button type="button" className="btn btn-success" onClick={this.reset}> Reset </button> 
            <Link to="/"> <button type="button" className="btn btn-danger"> Back </button> </Link>
        </div> 
        </td>
        </tr>
        </tbody>
    </table> <br></br>
  </div>

        <div className="col-md-12 col-xs-12 table-responsive coltable">
        <table id="myTable" className="table tableborder" border="1" width="100%">
        <thead>
              <tr className="header">
                <th>User</th>
                <th>Order-ID</th>
                <th>Sales-No</th>
                <th>Trans Date</th>
                <th>P-Type</th>
                <th>Total</th>
                <th>#</th>
              </tr>
        </thead>
        <tbody>          
        {
            this.state.data.map((dynamicData,index) => {
                return (
                <Fragment key={index}>
                <tr>
                    <td>{dynamicData.username}</td>
                    <td>{dynamicData.orderid}</td>
                    <td>{dynamicData.no}</td>
                    <td>{dynamicData.date} : {dynamicData.time}</td>
                    <td>{dynamicData.payment}</td>
                    <td>{API.formatNumber(dynamicData.total)}</td>
                    <td> 
                    <button id={dynamicData.orderid} onClick={this.prints} className="btn btn-primary btn-xs">[...]</button>
                    <button id={dynamicData.id} onClick={this.delete} className="btn btn-danger btn-xs">[...]</button>
                    </td>
                </tr>
                </Fragment>                
                );
            })
        }
                </tbody>    
                </table>  
        </div>
        </div>
                </Fragment>
            )
        }else{ return ( <Loading> </Loading> ) }
    }
}

const mapStateToProps = (state) => {
    return {
        loggedin : state.Loggedin,
        datatrans : state.Datatrans
    }
}

// let datax = new Sales().state.datatrans;
const mapDispatchToProps = (dispatch,param) => {
    return {
        set_true : () => dispatch({type: 'SET_TRUE'}),
        set_false : () => dispatch({type: 'SET_FALSE'}),
        loading : (param) => dispatch({type: 'LOADING', newValue:param})
    }
 }

// const mapDispatchToProps = (dispatch) => {
//    return {
//        set_true : () => dispatch({type: ActionType.SET_TRUE}),
//        set_false : () => dispatch({type: ActionType.SET_FALSE})
//    }
// }

export default connect(mapStateToProps,mapDispatchToProps)(Translist);
// export default Sales;