import React, { Component } from 'react';
import './Chart.css';
import { Bar, defaults } from 'react-chartjs-2';

defaults.global.maintainAspectRatio = false

class Chart extends Component {

  state = { labels:[], data:[] }

  componentDidMount(){
      this.setState({
          labels:['10/04/2018', '10/05/2018', '10/06/2018', '10/07/2018', '10/08/2018', '10/09/2018'],
          data:[19,25,27,23,22,24]
      })
  }  

  render() {
    const data = {
      labels: this.props.labels,
      datasets: [
        {
          label: this.props.title,
          data: this.props.data,
          fill: false,          // Don't fill area under the line
          borderColor: 'green'  // Line color
        }
      ]
    }

    return (
      <div className="App">
        <header className="App-header">
          {/* <h1>Responsive Linear chart using Chart.js</h1> */}
        </header>
        <article className="canvas-container">
          <Bar data={data} />
        </article>
      </div>
    );
  }
}

export default Chart;