import React, { Component,Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
// import swal from 'sweetalert';
import API from '../../services';
import {connect} from 'react-redux';
// import ActionType from '../../redux/reducer/globalActionType';
import Tbl from './comp/tbl';
import Select from 'react-select';
import Loading from '../../loading';

class Contact extends Component {

    constructor(props){
        super(props);
        this.state = { data: [], city:[], load:false }
    }

    componentDidMount(){
        this.token_otentikasi();
    }

    token_otentikasi = async() => { 
        const stts = await API.otentikasi();
        if (stts === true){ this.load_cust(); this.props.set_true(); }else if(stts === false){ this.props.set_false(); }
    }
 

    load_cust = () => {

        this.setState({ load:false });
        API.customer({city:"",publish: ""}).then(result => {
            this.setState({ data: result, load:true });
            this.get_city();
            // console.log('Data Customer : ', result);  
        }).catch((err) => {
            console.log("Error : ", err.response.statusText+' - '+err.response.status);
            API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
            API.otentikasi_acl();
        })
    }

    get_city = () => {
        API.city().then(result => {
            this.setState({ city: result.content });
        }).catch((err) => {
            // swal("Request Error","","error");
            // console.log("Error get_city : ", err.response.statusText+' - '+err.response.status);
            API.post_error_db('city',err.response.statusText,err.response.status);
            API.otentikasi_acl('city');
        })
    }

    handleChange = selectedOption => {
        this.setState({ load:false });
        API.customer({city: selectedOption.value,publish: "1"}).then(result => {
            this.setState({ data: result, load:true });
            // console.log('Data Customer : ', result);  
        }).catch((err) => {
            // console.log("Error get_handle_change : ", err.response.statusText+' - '+err.response.status);
            API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
            API.otentikasi_acl('city search');
        })
    };

    componentWillUnmount(){
        return true;
    }

    render() {
        const { load } = this.state;
        if (this.props.loggedin === false){ return <Redirect to='/login'></Redirect>}
        if (load === true){
           return (
                <Fragment>
    
    <h3 className="head1"> Contact List </h3> 
    <div className="col-md-3 col-xs-12">

    <table>
        <tbody>
        <tr>
        <td> <Select className="ccombo" onChange={this.handleChange} options={this.state.city}/> </td>
        <td>
        <div className="btn-group">
           <button type="button" className="btn btn-success" onClick={this.load_cust}> Reset </button> 
           <Link to="/add_contact"> <button type="button" className="btn btn-primary"> New </button> </Link>
           <Link to="/"> <button type="button" className="btn btn-danger"> Back </button> </Link>
        </div> 
        </td>
        </tr>
        </tbody>
    </table> <br></br>
            
    </div>
    <div className="row">
    <div className="col-md-12 col-xs-12 no-pad table-responsive">
        <Tbl data={this.state.data}> </Tbl>
    </div>
        
    </div>
                </Fragment>
            )
        }else{ return ( <Fragment> <Loading></Loading> </Fragment> ) }
    }
}

const mapStateToProps = (state) => {
    return { loggedin : state.Loggedin }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_true : () => dispatch({type: 'SET_TRUE'}),
        set_false : () => dispatch({type: 'SET_FALSE'})
    }
 }

export default connect(mapStateToProps,mapDispatchToProps)(Contact);