import React, { Component,Fragment } from 'react'
import API from '../../../services';
import swal from 'sweetalert';
// import $ from 'jquery';
import db from '../../../services/db';
import axios from 'axios';

export default class Prodetails extends Component {

    state = { data:[],total:0, sku:'',tax:0, taxval:0 }
    total = 0;

    componentDidMount() {
       this.tax(); 
    }

    tax = () => {
        API.tax().then(result => {
            this.setState({ data: result.content.result });
        }).catch((err) => {
            console.log("Error get_tax : ", err); API.otentikasi_acl('tax');
            API.post_error_db(this.constructor.name.toLowerCase(),"Error get_tax : ",err.response.status);
        })
    }

    calculate = (event) => {
        // console.log(event.target.value);
        if (event.target.value){
            this.setState({ sku:this.props.data.sku });
            let discount = document.getElementById("tdiscount").value;
            let qty = document.getElementById("tqty").value;
            let tax = document.getElementById("ctax").value;

            let hasil = parseInt(this.props.data.price*qty);
            if (hasil >= discount){ 
                let taxres = parseInt(tax*hasil);
                this.setState({ total:parseInt(hasil-discount+taxres), tax:taxres, taxval:tax });  
            }
            else{ let taxres = parseInt(tax*hasil); document.getElementById("tdiscount").value = 0; this.setState({ total:hasil+taxres, tax:taxres }); }
        }
    }

    cek_sku = async(sku,user) =>{
        // let firstFriend = await db.posts.get(sku);
        // let result = await db.posts.where('sku').equalsIgnoreCase(sku).and(value => value.user === user).toArray();

        // let result = await db.posts.where('user').equals(user).toArray();
        let result = await db.posts.where('sku').equals(sku).and(value => value.user === user).toArray();
        // return result.length;
        if (result.length === 0){ return true; }else{ return false; }
    }

    cek_sku_api = async(sku,qty)=>{
        return axios.get(localStorage.getItem('url')+'pos/valid_qty/'+sku+'/'+qty,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            return true;
            //  console.log(response);
        })
        .catch((err) => { return false; 
            API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
                            // console.log("Error pos valid qty : ", err.response); 
                            // if (err.response.status === 401){
                            //     API.otentikasi_acl('pos cek_sku'); 
                            // }else{ 
                            //     API.SwalTimer(err.response.data.error,sku,'warning',2000);
                            // }
                        }); 
    }

    valid_total = (total,discount)=>{
        if (parseInt(total) === 0 && parseInt(discount) === 0){ return false;}else { return true; }
    }

    post_data = async() => {
        let discount = document.getElementById("tdiscount").value;
        let qty = document.getElementById("tqty").value;
        let total = 0, tax = 0;

        if (this.props.data.sku !== this.state.sku){ total = this.props.data.total;}
        else{ total = this.state.total; tax = this.state.tax; }

        let valid = await this.cek_sku(this.props.data.sku,this.props.user);
        let valid_api = await this.cek_sku_api(this.props.data.sku,qty);

        if (valid_api === true && this.valid_total(total,discount) === true){
            // add data to database
            if (valid === true){
                let postdata = {sku: this.props.data.sku, name: this.props.data.name.toUpperCase(), qty: qty, user:this.props.user, price:this.props.data.price, discount:discount, tax:tax, taxval:this.state.taxval, total:total}
                db.posts.add(postdata).then(async()=>{swal('item succesfull added',this.props.data.sku,'success');})
            }else{
                let result = await db.posts.where('sku').equals(this.props.data.sku).and(value => value.user === this.props.user).toArray();
                qty = parseInt(qty)+parseInt(result[0].qty);

                let postdata = {
                  id:result[0].id, sku: this.props.data.sku, name: this.props.data.name.toUpperCase(), qty: qty, user:this.props.user, price:this.props.data.price, discount:discount, tax:tax, taxval:this.state.taxval, total:total
                }

                 db.posts.put(postdata).then(async()=>{
                    // console.log('berhasil ubah data');
                    swal('item succesfull updated',this.props.data.sku,'success');
                 })     
            }
            this.props.load_data();
            this.props.handle_close();

        }
        else if(this.valid_total(total,discount) === false){ API.SwalTimer('Invalid Total',this.props.data.sku,'error',900); } 
        else{ API.SwalTimer('Invalid Request Qty',this.props.data.sku,'warning',2000); }
    }

    render() {
        if (this.props.data.load === true){

            if (this.props.data.sku !== this.state.sku){ this.total = this.props.data.total;}
            else{ this.total = this.state.total; }

            return( 
                <Fragment>    
                <table className="table">
                <tbody>
                    <tr><td><label> SKU </label></td><td>{this.props.data.sku}</td></tr>
                    <tr><td><label> Name </label></td><td>{this.props.data.name.toUpperCase()}</td></tr>
                    <tr><td><label> Qty </label></td><td> <input type="number" className="tqty" id="tqty" defaultValue={this.props.data.qty} onChange={this.calculate}></input> </td></tr>
                    <tr><td><label> Price </label></td><td>{API.formatNumber(this.props.data.price)},-</td></tr>
                    <tr><td><label> Discount </label></td><td> <input type="number" id="tdiscount" className="tdiscount" defaultValue={this.props.data.discount} onChange={this.calculate}></input> </td></tr>
                    <tr><td><label> Tax </label></td>
                    <td>
                    <select name="ctax" id="ctax" className="slc slc2" onChange={this.calculate}>
                    {
                        this.state.data.map((dynamicData,index) => {
                            return (
                                <Fragment key={index}>
                                  <option value={dynamicData.value}>{API.capitalize(dynamicData.code)}</option>
                                </Fragment>                
                            );
                        })
                    }
                    </select>
                    </td></tr>
                    <tr><td><label> Total </label></td><td>{ API.formatNumber(this.total)},- </td></tr>
                    <tr><td></td><td> <button className="btn btn-primary" onClick={this.post_data}> POST </button> </td></tr>
                </tbody>    
                </table> <br></br>
                <img src={this.props.data.image} className="img-thumbnail" alt={this.props.data.sku}></img>  
                <hr></hr>
                </Fragment>
            )
        }else{ return ( <Fragment></Fragment> ) }   
       } 
}