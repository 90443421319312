import React, { Component,Fragment } from 'react'
import API from '../../../services'

export default class Combo_payment extends Component {

    state = { data:[] }

    componentDidMount() {
        API.payment().then(result => {
            this.setState({ data: result.content.result });
        }).catch((err) => {
            console.log("Error get_payment : ", err); API.otentikasi_acl('payment');
            API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
        })
    }

    render() {
        return <Fragment>
        <select name="cpayment" id="cpayment" className={this.props.class}>
        {
            this.state.data.map((dynamicData,index) => {
                return (
                    <Fragment key={index}>
                    <option value={dynamicData.id}>{API.capitalize(dynamicData.name)}</option>
                    </Fragment>                
                );
            })
        }
        </select>
        </Fragment>
        
       } 
}