import React, { Component,Fragment } from 'react'
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
// import $ from "jquery";
import {faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import API from './../../services';
import $ from 'jquery';

export default class Otp extends Component {

    constructor(props){
        super(props)
        this.state = {loggedIn:false}
    }

    componentDidMount(){
        localStorage.removeItem('url');
        localStorage.removeItem('token');
        $("#otp").focus();
    }

    vivible_pass = () => {
        var x = document.getElementById("otp");
        if (x.type === "password") {x.type = "text";} else {x.type = "password";}
    }

    handleInput=(e)=>{
        if (e.key === 'Enter'){ this.login(); }
    }

    login = () =>{
        
      let otp = document.getElementById("otp").value;
      if (otp !== ""){
        axios.get('http://crmsaas.dswip.com/sales/get_url/'+otp)
        .then((response) => {
            // console.log("Ini response : ",response);
            localStorage.setItem('url', response.data.response);
            this.setState({loggedIn:true});
        })
        .catch((error) => { document.getElementById("otp").value = ""; 
            API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.response,error.response.status);
            // console.log(error.response.data.response); 
            swal("Invalid OTP Code", "", "error");
            // $("#otp").focus();
        }); 
      }else{ swal('Otp required','','error'); $("#otp").focus(); }
    }

    render() {
        if (this.state.loggedIn === true){ return <Redirect to='/login'></Redirect>}
        return (
            <Fragment>
<div className="thebox">
<h1 className="head1"> OTP </h1>
    <form action="">
            <label> Token </label>
            <table>
                <tbody>
                <tr> 
                <td> <input type="password" className="form-control" id="otp" placeholder="Password" onKeyUp={this.handleInput}></input> </td> 
                <td> <FontAwesomeIcon icon={faEye} className="glyphicon" onClick={this.vivible_pass} /> </td>
                </tr>
                </tbody>
            </table>
            <button type="button" className="btn loginbtn" onClick={this.login}> Set </button>
    </form>
</div>
            </Fragment>
        )
    }
}
