import React, { Component,Fragment } from 'react'
import {Link } from 'react-router-dom'
import swal from 'sweetalert';
import API from '../../services';
import {connect} from 'react-redux';
// import ActionType from '../../redux/reducer/globalActionType';
import Select from 'react-select';
import axios from 'axios';

const qs = require('querystring');


class Contact_add extends Component {

    constructor(props){
        super(props);
        this.state = { data: [], city:[], cityid:0 }
    }

    componentDidMount(){
        this.get_city();
    }

    get_city = () => {
        API.city().then(result => {
            this.setState({ city: result.content });
        }).catch((err) => {
            console.log("Error : ", err.response.statusText+' - '+err.response.status);
            API.otentikasi_acl('city');
            API.post_error_db('city',err.response.statusText,err.response.status);
        })
    }

    handleChange = selectedOption => {
        this.setState({ cityid: selectedOption.value });
      };

    postdata = () => {
        
        let city = this.state.cityid;
        let name = document.getElementById("tname").value;
        let phone = document.getElementById("tphone").value;
        let email = document.getElementById("temail").value;
        let address = document.getElementById("taddress").value;

        const requestBody = {
            tfname: name,tlname: '-',
            ctype: 'customer',
            tphone1: phone,tphone2: '0',
            tmobile: '0', temail: email,
            taddress: address, ccity: city,
            twebsite: '',tzip: '',cdistrict: '1',
            userfile: null
          }
        
        axios.post(localStorage.getItem('url')+"customer/add",qs.stringify(requestBody),{headers: {'X-Auth-Token': localStorage.getItem('token'),'Content-Type': 'application/x-www-form-urlencoded'}})
          .then((result) => { this.resetform(); swal('Contact Saved','','success'); })
          .catch((err) => {
            // console.log("Error : ", err.response.statusText+' - '+err.response.status);
            API.post_error_db('contact',err.response.statusText,err.response.status);
            if (err.response.status === 401){ API.otentikasi_acl('customer add'); }
            else{ API.SwalHTML(err.response.data.error); }
          }) 
    }

    resetform = () => {
        document.getElementById("tname").value = "";
        document.getElementById("tphone").value = "";
        document.getElementById("temail").value = "";
        document.getElementById("taddress").value = ""; 
    }

    render() {
        
           return (
                <Fragment>
    
<h3 className="head1"> </h3> 
<div className="content">
		<h3>Tambah Kontak Baru</h3>
		<div className="box">
            <form>
    <table>
        <tbody>
            <tr><td><span>City</span></td><td> <Select className="kontak_input" onChange={this.handleChange} options={this.state.city}/></td></tr>
            <tr><td><span>Nama</span></td><td><input type="text" id="tname" className="kontak_input"></input></td></tr>
            <tr><td><span>Telepon</span></td><td><input type="text" id="tphone" className="kontak_input"></input></td></tr>
            <tr><td><span>Email</span></td><td><input type="email" id="temail" className="kontak_input"></input></td></tr>
            <tr><td><span>Address</span></td><td><textarea id="taddress" className="kontak_input" cols="1" rows="3"></textarea></td></tr>
        </tbody>
    </table>
              
            </form>
        </div>
		<div className="menubtn">
			<button type="button" onClick={this.postdata}> Post </button>
			<Link to="/contact"><button>Back</button></Link>
		</div>
</div>
                </Fragment>
            )
        
    }
}

const mapStateToProps = (state) => {
    return { loggedin : state.Loggedin }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_true : () => dispatch({type: 'SET_TRUE'}),
        set_false : () => dispatch({type: 'SET_FALSE'})
    }
 }

export default connect(mapStateToProps,mapDispatchToProps)(Contact_add);