import React, { Component,Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
// import swal from 'sweetalert';
import API from '../../services';
import {connect} from 'react-redux';
// import ActionType from '../../redux/reducer/globalActionType';
import $ from 'jquery';
import axios from 'axios';
import Tabel from './comp/tabel';
import Tabeltrans from './comp/tabeltrans';
import Combopayment from './comp/combo_payment';
import Clock from '../../services/clock';
import db from '../../services/db';

class Sales extends Component {

    state = { data:[], datatrans:[], bruto_tot:0, bruto_tot_qty:0,
              load:true, userid:0, username:'',rules:0,log:0,
              modal:false, orderid:0, showtrans:true
            }
    time = null; date = null;
    today = new Date();
    child = null

    constructor(props){
        super(props);
        this.child = React.createRef();
    }

    load_data_trans = async() => {

        this.setState({datatrans:[]});
        let total = 0; let totalqty = 0;
        let result = await db.posts.where('user').equalsIgnoreCase(this.state.userid).toArray();
        this.setState({ datatrans: result });
        result.map((dynamicData,index) => {total = parseInt(total+dynamicData.total); totalqty = parseInt(totalqty)+parseInt(dynamicData.qty);})
        this.setState({ bruto_tot: total, bruto_tot_qty: totalqty }); 
    };

    componentDidMount(){
        $("#tsearch").focus();
        this.token_otentikasi();
        this.date = this.today.getDate()+'/'+(this.today.getMonth()+1)+'/'+this.today.getFullYear();
    }

    decode = async() => {
       const decode = await API.decode();
       this.setState({ userid: decode.userid,username:decode.username,rules:decode.rules,log:decode.log }); 
       this.load_data_trans();
        return axios.get(localStorage.getItem('url')+'pos',{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { this.setState({orderid:response.data.content.orderid}) })
        .catch((err) => { console.log(err); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); }); 
    }

    token_otentikasi = async() => { 
        const stts = await API.otentikasi();
        if (stts === true){ this.decode(); this.props.set_true(); }else if(stts === false){ this.props.set_false(); }
    }

    reset = () => {
        $("#tsearch").val(""); $("#ctype").val("0"); this.setState({ data: []}); $("#tsearch").focus();
    }

    cek_sku = async(sku) =>{
        let result = await db.posts.where('sku').equals(sku).and(value => value.user === this.state.userid).toArray();
        if (result.length === 0){ return true; }else{ return false; }
    }

    cek_sku_api = async(sku,qty=1)=>{
        return axios.get(localStorage.getItem('url')+'pos/valid_qty/'+sku+'/'+qty,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { return true; })
        .catch((err) => { return false; API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); }); 
    }

    post_data = async(data) => {

        // console.log(data[0].id)
        // let total = 0, tax = 0;

        let valid = await this.cek_sku(data[0].sku);
        let valid_api = await this.cek_sku_api(data[0].sku);

        if (valid_api === true && data[0].price !== 0){
            
            // add data to database
            if (valid === true){
                let total = 0.1*parseInt(data[0].price)+parseInt(data[0].price);
                let postdata = {sku: data[0].sku, name: data[0].name.toUpperCase(), qty: 1, user:this.state.userid, price:data[0].price, discount:0, tax:0.1*parseInt(data[0].price), taxval:0.1, total:total}
                db.posts.add(postdata).then(async()=>{
                    API.SwalTimer('item succesfull added',data[0].sku,'success',900);
                })
            }else{
                let qty=0;
                let result = await db.posts.where('sku').equals(data[0].sku).and(value => value.user === this.state.userid).toArray();
                qty = parseInt(1)+parseInt(result[0].qty);
                let valid_api = await this.cek_sku_api(data[0].sku,qty);

                if (valid_api === true){
                    let total = 0.1*parseInt(qty*data[0].price)+parseInt(qty*data[0].price);
                    let postdata = {
                      id:result[0].id, sku: data[0].sku, name: data[0].name.toUpperCase(), qty: qty, user:this.state.userid, price:data[0].price, discount:0, taxval:0.1, tax:0.1*parseInt(qty*data[0].price), total:total
                    }
                   db.posts.put(postdata).then(async()=>{ /*API.SwalTimer('item succesfull updated',data[0].sku,'success',900); */ })     
                }else{ API.SwalTimer('Invalid Request Qty',data[0].sku,'warning',800); }
            }

            this.load_data_trans(); this.reset();
        }
        else if(data[0].price === 0){ API.SwalTimer('Invalid Total',data[0].sku,'error',900); } 
        else{ API.SwalTimer('Invalid Request Qty',data[0].sku,'warning',2000); }
    }

    texthandle = event => {

        let type = $("#ctype").val();

        if (type === '0'){
            let param = {branch:"",category:"",brand:"",color:"",size:"",publish: "1",sku:event.target.value};
            axios.post(localStorage.getItem('url')+'product',param,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
            .then((response) => { 
                if (response.data.content.result != null){
                    this.setState({ data: response.data.content.result,load:true }); 
                    this.post_data(response.data.content.result);
                }else{
                    this.setState({ data:[] }); 
                }
            }).catch((err) => { console.log("Error texthandle : ", err); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); API.otentikasi_acl('product search'); });
        }else if(type === '1'){
            if (event.target.value){
                let param = {filter:event.target.value};
                axios.post(localStorage.getItem('url')+'product/search',param,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
                .then((response) => { 
                    if (response.data.content.result != null){
                        this.setState({ data: response.data.content.result }); 
                    }
                }).catch((err) => { console.log("Error texthandle : ", err); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); API.otentikasi_acl('product search'); });
    
            }else{ this.setState({ data: [] });  }
        }
    }

    cek_type = event => { $("#tsearch").focus()}

    componentWillUnmount(){ return true; }

    triggerChildAlert=()=>{
        // console.log(this.state.showtrans);
        if (this.state.showtrans === true){ this.setState({showtrans:false}); }
        else{ this.setState({showtrans:true}); }
    }

    render() {
        const { load } = this.state;
        if (this.props.loggedin === false){ return <Redirect to='/login'></Redirect>}
        if (load === true){
            return (
                <Fragment>
    
<h3 className="head1"> Transaction </h3> <br></br>
<div className="row">
       <div className="col-md-5 col-xs-12">
      		<table style={{width:"300px"}}>
              <tbody>
      			<tr>
      				<td>No. Ref</td>
      				<td>:</td>
      				<td>POS-{this.state.orderid}</td>
      			</tr>
      			<tr>
      				<td> Trans-Date </td>
      				<td>:</td>
      				<td> {this.date} - {<Clock></Clock>}</td>
      			</tr>
      			<tr>
      				<td> Payment Type </td>
      				<td>:</td>
					<td>
                     <Combopayment class="slc slc2"></Combopayment>
					</td>
      			</tr>
      			<tr>
      				<td>Kasir</td>
      				<td>:</td>
      				<td>{API.capitalize(this.state.username)}</td>
      			</tr>
                <tr>
      				<td></td>
      				<td></td>
      				<td> 
<div className="btn-group">
<button id="bhidex" className="btn btn-dark btn-xs" onClick={this.triggerChildAlert}> Show / Hide Transaction </button> 
</div>
                    </td>
      			</tr>
                </tbody>
      		</table>
       		
       </div>

   	  <div className="col-md-4 offset-md-3 col-xs-12"> <h1> Rp {API.formatNumber(this.state.bruto_tot)},- </h1> </div>
</div> <br></br>

<div className="row">
    {/* tabel transaksi */}
    <Tabeltrans decode={this.decode} log={this.state.log}
                reftrans={this.state.showtrans} orderid={this.state.orderid} 
                user={this.state.userid} total={this.state.bruto_tot} total_qty={this.state.bruto_tot_qty} 
                load_data={this.load_data_trans} data={this.state.datatrans}>
    </Tabeltrans>    
</div>

<div className="row">
      <div className="col-md-9 col-xs-12 no-pad table-responsive">
       <br></br>
        <table>
        <tbody>
        <tr>
        <td> <select name="ctype" id="ctype" className="form-control ctypex" onChange={this.cek_type}>
                <option value="0"> SKU </option>
                <option value="1"> Name / Model </option>
             </select> </td>
        <td> <input type="text" id="tsearch" placeholder="Search.." onChange={this.texthandle} className="form-control"></input> </td> 
        <td>
        <div className="btn-group">
            <button type="button" className="btn btn-success" onClick={this.reset}> Reset </button> 
            <Link to="/"> <button type="button" className="btn btn-danger"> Back </button> </Link>
        </div> 
        </td>
        </tr>
        </tbody>
    </table> <br></br>
        </div>
</div> 

        <div className="row">
            <Tabel user={this.state.userid} load_data={this.load_data_trans} data={this.state.data}></Tabel>
        </div>
                </Fragment>
            )
        }else{ return (<Fragment> <h3> Loading </h3> </Fragment>) }
    }
}

const mapStateToProps = (state) => {
    return {
        loggedin : state.Loggedin,
        datatrans : state.Datatrans
    }
}

// let datax = new Sales().state.datatrans;
const mapDispatchToProps = (dispatch,param) => {
    return {
        set_true : () => dispatch({type: 'SET_TRUE'}),
        set_false : () => dispatch({type: 'SET_FALSE'}),
        loading : (param) => dispatch({type: 'LOADING', newValue:param})
    }
 }

// const mapDispatchToProps = (dispatch) => {
//    return {
//        set_true : () => dispatch({type: ActionType.SET_TRUE}),
//        set_false : () => dispatch({type: ActionType.SET_FALSE})
//    }
// }

export default connect(mapStateToProps,mapDispatchToProps)(Sales);
// export default Sales;