import React, { Component,Fragment } from 'react'
import axios from 'axios';
import Prodetails from './prodetails'
import API from '../../../services/index';

export default class Tabel extends Component {

    state = { 
            name:'None',
            category:'None',
            brand:'None',
            color:'None',
            qty:0,
            price:0,
            image:null,
            load:false
   }

    componentDidMount() {
        // console.log(this.state.proDetails.image);
    }

    details = event => {
        // console.log(event.target.id);
        axios.get(localStorage.getItem('url')+'product/details/'+event.target.id,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 

            // console.log(response.data.content);
            this.setState({ 
                name: response.data.content.name, category:response.data.content.category, brand:response.data.content.brand,
                color:response.data.content.color, qty:response.data.content.qty, price:response.data.content.price,
                image:response.data.content.image, load:true
             }); 
        })
        .catch((err) => { console.log("Error texthandle : ", err); 
                          API.post_error_db('product',err.response.statusText,err.response.status); API.otentikasi_acl('product details'); }); 
    }

    render() {
        return <Fragment>
        <div className="col-md-9 col-xs-12 no-pad table-responsive">
        <table id="myTable" className="table table-striped table-bordered"> 
        <thead>
              <tr className="header">
                <th>SKU</th>
                <th>Brand</th>
                <th>Name</th>
                <th>Qty</th>
                <th>#</th>
              </tr>
        </thead>
        <tbody>          
        {
            this.props.data.map((dynamicData,index) => {
                return (
                <Fragment key={index}>
                <tr>
                    <td>{dynamicData.sku}</td>
                    <td>{dynamicData.brand}</td>
                    <td>{dynamicData.name}</td>
                    <td>{dynamicData.qty}</td>
                    <td> 
                    <button id={dynamicData.id} onClick={this.details} className="btn btn-primary btn-xs">[...]</button>
                    </td>
                </tr>
                </Fragment>                
                );
            })

        }
                </tbody>    
                </table>  
                </div>   
                <Prodetails data={this.state}></Prodetails>
        </Fragment>
        
       } 
}