import React, { Component,Fragment } from 'react'
// import axios from 'axios';
import API from '../../../services'
import Prodetailsupdate from './prodetailsupdate';
import db from '../../../services/db';
import $ from 'jquery';
import axios from 'axios';

export default class Tabeltrans extends Component {

    constructor(props) {
        super(props);
        this.showhide = this.showhide.bind(this);
    }

    state = { 
        id:0,
        sku:'',
        name:'',
        qty:0,
        price:0,
        discount:0,
        total:0,
        user:0,
        taxval:0,
        load:false,counter:0
   }
   total = 0;

   componentDidMount(){
   }

    componentWillUnmount(){
        return true;
    }

    set_load_false = () => {
        this.setState({ load:false }); 
    }

    remove_trans = event => {
        db.posts.delete(parseInt(event.target.id)).then(async()=>{ this.props.load_data();})
    }

    clear_data = () => {
        db.posts.where('user').equalsIgnoreCase(this.props.user).delete().then(async()=>{
            this.props.load_data();
        })
    }

    showhide = () =>{

        // alert(this.props.reftrans);

        if (this.props.reftrans === true){ 
             $("#transbox").fadeIn();
        }
        else{ 
          $("#transbox").fadeOut(); 
        }
    }

    update = async(event) => {
        
        // console.log('Value : ',event.target.value);
        this.setState({load:false});
        let data = await db.posts.get(parseInt(event.target.id));
        this.setState({ 
            id:data.id,
            sku:data.sku,
            name:data.name,
            qty:parseInt(data.qty),
            price:data.price,
            discount:parseInt(data.discount), 
            total:data.total,
            user:data.user,
            taxval:data.taxval,
            load:true
        }); 
    }

    cek_sku_api = async(sku,qty=1)=>{
        return axios.get(localStorage.getItem('url')+'pos/valid_qty/'+sku+'/'+qty,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { return true; })
        .catch((err) => { return false; }); 
    }

    editOnchange = async(event)=>{
        // console.log(event.target.value);
        if (event.target.value){
            
           let qty = parseInt(event.target.value); 
           if (qty === 0){ db.posts.delete(parseInt(event.target.id)).then(async()=>{ this.props.load_data();}) }
           else{
               let data = await db.posts.get(parseInt(event.target.id));
               let valid_api = await this.cek_sku_api(data.sku,qty);
                if (valid_api === true){
                    let tax = qty*parseInt(data.price)*parseFloat(data.taxval);
                    let total = qty*parseInt(data.price)-parseInt(data.discount)+tax;
                    let postdata = {
                            id:data.id, sku: data.sku, name: data.name.toUpperCase(), qty: qty, user:data.user, price:data.price, discount:data.discount, tax:tax, taxval:data.taxval, total:total
                        }

                        db.posts.put(postdata).then(async()=>{
                        // console.log('berhasil ubah data');
                        // API.SwalTimer('item succesfull updated',this.props.data.sku,'success',900);
                        this.props.load_data();
                        })

                }else{ this.props.load_data();
                        API.SwalTimer('Invalid Request Qty',data.sku,'warning',800); this.props.load_data(); 
                        }
           }             
        }
    }

    posting = async() =>{
        let payment = $("#cpayment").val();
        let result = await db.posts.where('user').equalsIgnoreCase(this.props.user).toArray();
        if (result.length > 0){ 
            result.map((dynamicData,index) => {
                // console.log(dynamicData.id)
                
                if (this.props.log !== 0){
                    let requestBody = {
                        payment: payment,product: parseInt(dynamicData.sku),
                        qty: parseInt(dynamicData.qty), price: parseInt(dynamicData.price),tax: parseInt(dynamicData.taxval),
                        orderid: this.props.orderid, log:this.props.log
                    }

                    // console.log(requestBody);
                    API.Post_form('pos/add',requestBody)
                    .then((result) => { 
                        // console.log(result.content); 
                        this.setState((prevState, props) => ({counter: prevState.counter + 1})); 
                        db.posts.delete(parseInt(dynamicData.id)).then(async()=>{ this.prints(this.props.orderid); this.props.decode(); this.props.load_data(); console.log('row removed') })
                    })
                    .catch((err) => { 
                        console.log("Error : ", err.response.data.error);
                        API.post_error_db(this.constructor.name.toLowerCase(),err.response.data.error,err.response.status);
                        //  API.SwalHTML(err.response.data.error);
                    }) 
                }
                
            })    
        }
    }

    changeCounter=()=> {
        if (this.state.counter > 0){ API.SwalTimer(this.state.counter,'row has been posted','success',1500); 
         this.setState((prevState, props) => ({counter:0})); }
    }

    prints=(orderid)=>{
        setTimeout(() => {

        //   let myWindow = window.open(localStorage.getItem('url')+'pos/invoice/'+orderid+'/invoice', 'POS - Invoice', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width="500", height="600", top='+top+', left='+left);
          let myWindow = window.open(localStorage.getItem('url')+'pos/invoice/'+orderid+'/invoice', "POS - Invoice", "width=500,height=600");
        }, 1200);
        // console.log(localStorage.getItem('url')+'pos/invoice/'+orderid+'/invoice');
    }

    render() {
    // console.log(this.props.reftrans);    
    this.showhide();
    this.changeCounter();
    return <Fragment>
<div className="row" id="transbox">
<div className="col-md-9 col-xs-12 table-responsive">
<table id="myTable" className="table" border="1">
<thead>
<tr className="header">
    <th>Kode</th>
    <th>Produk</th>
    <th>Qty</th>
    <th>Harga</th>
    <th>Diskon</th>
    <th>Tax(%)</th>
    <th>Total</th>
    <th> # </th>
</tr>
</thead>

<tbody>
{
    this.props.data.map((dynamicData,index) => {
        return (
        <Fragment key={index}>
        <tr>
            <td>{dynamicData.sku} <input type="hidden" name="hvalue" value={dynamicData.id}></input> </td>
            <td>{dynamicData.name.toUpperCase()}</td>
            {/* <td>{API.formatNumber(dynamicData.qty)}</td> */}
            <td> <input type="number" id={dynamicData.id} className="tqty" defaultValue={dynamicData.qty} onKeyUp={this.editOnchange}></input> </td>
            <td>{API.formatNumber(dynamicData.price)}</td>
            <td>{API.formatNumber(dynamicData.discount)}</td>
            <td>{API.formatNumber(dynamicData.tax)}</td>
            <td>{API.formatNumber(dynamicData.total)}</td>
<td> 
<div className="btn-group">
     <button id={dynamicData.id} onClick={this.update} className="btn btn-success btn-xs">[..]</button> 
     <button id={dynamicData.id} onClick={this.remove_trans} className="btn btn-danger btn-xs">[..]</button> 
</div>
</td>
        </tr>
        </Fragment>                
        );
    }) 
}
<tr>
<td colSpan="2"> 
<div className="btn-group">
   <button type="button" className="btn btn-danger" onClick={this.clear_data}> Clear </button> 
   <button type="button" className="btn btn-success" onClick={this.posting}> Posting </button> 
</div>
</td>
<td> <h3> { this.props.total_qty } </h3> </td>
<td colSpan="3"></td>
<td colSpan="2"><h3> {API.formatNumber(this.props.total)},- </h3></td>
</tr>
</tbody>
</table>
</div>

<div className="col-md-3 col-xs-12 table-responsive">
  <Prodetailsupdate set_load_false={this.set_load_false} load_data={this.props.load_data} data={this.state}></Prodetailsupdate>
</div>
</div>
        </Fragment>
        
        
       } 
}