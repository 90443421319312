import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
// import rootReducer from './redux/reducer/globalReducer';

const globalState = {
    Loggedin :true,
    Datatrans:[]
}

const rootReducer = (state = globalState, action) => {
    if (action.type === 'SET_TRUE'){return {...state,Loggedin: true}}
    if (action.type === 'SET_FALSE'){return {...state,Loggedin: false}}
    if (action.type === 'LOADING'){return {...state,Datatrans: action.newValue}}
    return state;
}

// Store
const storeRedux = createStore(rootReducer);

ReactDOM.render(<Provider store={storeRedux}><Routes /></Provider>, document.getElementById('root')); 
// ReactDOM.render(<App />, document.getElementById('root')); 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
