import React, { Component,Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
// import swal from 'sweetalert';
import API from '../../services';
import {connect} from 'react-redux';
// import ActionType from '../../redux/reducer/globalActionType';
import $ from 'jquery';
import axios from 'axios';
import Tabel from './comp/tabel';
import Select from 'react-select';
import Loading from '../../loading';

class Product extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], category :[],load:false }
    }

    componentDidMount(){
        this.token_otentikasi();
    }

    token_otentikasi = async() => { 
        const stts = await API.otentikasi();
        if (stts === true){ this.load_product(); this.props.set_true(); }else if(stts === false){ this.props.set_false(); }
    }

    load_product = () => {
        this.setState({ load:false });
        axios.get(localStorage.getItem('url')+'product',{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            this.setState({ data: response.data.content.result,load:true });
            $('#myTable').DataTable();
            this.get_category();
            // console.log(response.data.content.result);
        })
        .catch((err) => { console.log("Error load_product : ", err.response.statusText+' - '+err.response.status);
            API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         }); 
        
    }

    get_category = () => {
        API.category().then(result => {
            this.setState({ category: result.content });
        }).catch((err) => {
            console.log("Error get_category : ", err.data.error);
            API.otentikasi_acl('category');
            API.post_error_db('category',err.data.error);
        })
    }

    handleChange = selectedOption => {
        
        this.setState({load:false });
        // alert(selectedOption.value);
        let param = {branch:"",category: selectedOption.value,brand:"",color:"",size:"",publish: "1",sku:""};

        axios.post(localStorage.getItem('url')+'product',param,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            if (response.data.content.result != null){
                this.setState({ data: response.data.content.result,load:true }); 
                $('#myTable').DataTable(); 
            }else{
                this.setState({ load:true }); 
                $('#myTable').dataTable().fnClearTable();
            }
        }).catch((err) => { console.log("Error get_handle_change : ", err); API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); }); 
    };

    texthandle = event => {

        // this.setState({load:false });
        let param = {filter:event.target.value};
        let table = $('#myTable').DataTable();
        table.destroy();

        if (event.target.value){
            // console.log(event.target.value);
            axios.post(localStorage.getItem('url')+'product/search',param,{headers: {'X-Auth-Token': localStorage.getItem('token')}})
            .then((response) => { 
                if (response.data.content.result != null){
                    // console.log(response.data.content.result);
                    this.setState({ data: response.data.content.result,load:true }); 
                    // $('#myTable').DataTable(); 
                }
            }).catch((err) => { console.log("Error texthandle : ", err); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); API.otentikasi_acl('product search'); }); 
        }
    }

    render() {
        const { load } = this.state;
        if (this.props.loggedin === false){ return <Redirect to='/login'></Redirect>}

        if (load === true){
            return (
                <Fragment>
    
    <h3 className="head1"> Product List </h3> <br></br>
    
    <div className="row">
    <div className="col-md-9 col-xs-12">
    
    <table>
        <tbody>
        <tr>
        <td> <Select className="ccombo" onChange={this.handleChange} options={this.state.category}/> </td>
        <td> <input type="text" placeholder="Cari.." onChange={this.texthandle} className="form-control"></input> </td> 
        <td>
        <div className="btn-group">
            <button type="button" className="btn btn-success" onClick={this.load_product}> Reset </button> 
            <Link to="/"> <button type="button" className="btn btn-danger"> Back </button> </Link>
        </div> 
        </td>
        </tr>
        </tbody>
    </table> <br></br>
    </div>

    </div>

    <div className="row">
    <Tabel data={this.state.data}></Tabel>    
    </div>
    
                </Fragment>
            )
        }else{ return (<Fragment> <Loading></Loading> </Fragment>) }
    }
}

const mapStateToProps = (state) => {
    return {
        loggedin : state.Loggedin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_true : () => dispatch({type: 'SET_TRUE'}),
        set_false : () => dispatch({type: 'SET_FALSE'})
    }
 }

// const mapDispatchToProps = (dispatch) => {
//    return {
//        set_true : () => dispatch({type: ActionType.SET_TRUE}),
//        set_false : () => dispatch({type: ActionType.SET_FALSE})
//    }
// }

export default connect(mapStateToProps,mapDispatchToProps)(Product);