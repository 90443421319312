import React, {Component} from 'react'
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Login from "./container/login";
import Home from "./container/home";
import Otp from "./container/otp"
import Contact from "./container/contact"
import Contactadd from "./container/contact/contact_add"
import Product from "./container/product"
import Sales from "./container/sales"
import Translist from "./container/translist"
import Log from "./container/log"

class Routes extends Component{

    render(){
      return (
        <BrowserRouter>
            <Switch>
             {/* <Route path="/login" render={props => <Login {...props}/>}> <Login/> </Route> */}
             {/* <Route path="/" render={props => <Home {...props}/>}> <Home/> </Route> */}

            <Route path="/login"><Login /></Route> 
            <Route path="/otp"> <Otp /> </Route> 
            <Route path="/" exact> <Home /></Route>
            <Route path="/contact"> <Contact /> </Route>
            <Route path="/add_contact"> <Contactadd /> </Route>
            <Route path="/product"> <Product /> </Route>
            <Route path="/pos"> <Sales /> </Route>
            <Route path="/transaction"> <Translist/> </Route>
            <Route path="/log"> <Log/> </Route>
            </Switch>
        </BrowserRouter>
      )
    } 
}
export default Routes;
