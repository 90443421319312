import React, { Component,Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
import logo from './loading.gif';

class Loading extends Component {

    constructor(props){
        super(props);
        this.state = { redirect: false }
        this._isMounted = false;
    }

    componentDidMount(){
       setTimeout(() => {
        this._isMounted = true;
        this.setState({redirect: true});
       }, 15000)
    }

    componentWillUnmount(){
        // return true;
        this._isMounted = false;
    }

    render(){
        if (this.state.redirect === true){ return <Redirect to='/'></Redirect>}
        return (
            <Fragment>
              <div>
                <center> 
                <Link to="/"> <img src={logo} alt="loading" /> </Link>
                {/* <br></br> */}
                {/* <Link to="/"> <button type="button" className="btn btn-danger"> Back Home </button> </Link> */}
                </center>
             </div>
            </Fragment>
        )
    }
}

export default Loading;
