import React, { Component,Fragment } from 'react'

export default class Prodetails extends Component {

    componentDidMount() {
        // console.log(this.props.data.image);
    }

    render() {
        if (this.props.data.load === true){
            return( 
                <Fragment>
                <div className="col-md-3 col-xs-12">
                <img src={this.props.data.image} className="img-thumbnail" alt={this.props.data.name}></img>      
                <table>
                <tbody>
                    <tr><td><label> Name </label></td><td> {this.props.data.name} </td></tr>
                    <tr><td><label> Category / Brand </label></td><td> {this.props.data.category} / {this.props.data.brand} </td></tr>
                    <tr><td><label> Color </label></td><td>{this.props.data.color}</td></tr>
                    <tr><td><label> Qty(pcs) </label></td><td>{this.props.data.qty}</td></tr>
                    <tr><td><label> Price </label></td><td>{this.props.data.price},-</td></tr>
                </tbody>    
                </table>
                </div>
                </Fragment>
            )
        }else{ return ( <Fragment></Fragment> ) }   
       } 
}