import React, { Component,Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
// import swal from 'sweetalert';
import API from '../../services';
import {connect} from 'react-redux';
// import ActionType from '../../redux/reducer/globalActionType';
import $ from 'jquery';
import db from '../../services/db';
import Tabel from './comp/tabel';
import Loading from '../../loading';

class Log extends Component {

    state = { data:[], category :[],load:false,rules:'0',loadtable:false }

    componentDidMount(){
        this.decode();
    }

    decode = async() => {
       const decode = await API.decode();
       this.setState({rules:decode.rules}, () => {this.token_otentikasi()})
    }

    token_otentikasi = async() => { 
        const stts = await API.otentikasi();
        if (stts === true && this.state.rules === '3'){ this.props.set_true(); this.load_log(); }
        else if(stts === false && this.this.state.rules !== '3'){ this.props.set_false(); }
    }

    load_log = async() => {
        this.setState({ load:false });
        let result = await db.table("errors").toArray();
        this.setState({ data: result, load:true, loadtable:true });
        $('#myTable').DataTable();
    }

    clear = async() => {
        await db.errors.clear();
        this.load_log();
    }

    search = async() => {
         
        let input = document.getElementById("ccode").value;
        let modul = document.getElementById("cmodul").value;
        let result = []; $('#myTable').dataTable().fnClearTable(); 
        this.setState({ loadtable:false }); 
        
        if (input !== "" && modul === "" ){
            result = await db.errors.where('code').equals(parseInt(input)).toArray();
        }else if(input === "" && modul !== ""){
            result = await db.errors.where('modul').equals(modul).toArray();
        }else if (input !== "" && modul !== "" ){
            result = await db.errors.where('code').equals(parseInt(input)).and(value => value.modul === modul).toArray();
        }
        if (result.length !== 0){ this.setState({ data: result,loadtable:true},() => { $('#myTable').dataTable(); });}
    };

    render() {
        const { load } = this.state;
        if (this.props.loggedin === false){ return <Redirect to='/login'></Redirect>}

        if (load === true){
            return (
                <Fragment>
    
    <h3 className="head1"> Error Log : </h3> <br></br>
    
    <div className="row">
    <div className="col-md-9 col-xs-12">
    
    <table>
        <tbody>
        <tr>
        <td> <select id="ccode" className="form-control" onChange={this.search}> <option value=""> -- </option> <option value='400'> 400 </option> <option value='401'> 401 </option> <option value='403'> 403 </option> <option value='404'> 404 </option> </select> </td>
        <td> <select id="cmodul" className="form-control" onChange={this.search}> 
             <option value=""> -- </option> 
             <option value='login'> Login </option>
             <option value='home'> Home </option>
             <option value='otp'> OTP </option>
             <option value='contact'> Contact </option>
             <option value='product'> Product </option> 
             <option value='sales'> POS </option>
             <option value='translist'> Transaction </option>
             </select> 
        </td> 
        <td>
        <div className="btn-group">
            <button type="button" className="btn btn-success" onClick={this.load_log}> Reset </button> 
            <button type="button" className="btn btn-warning" onClick={this.clear}> Clear </button> 
            <Link to="/"> <button type="button" className="btn btn-danger"> Back </button> </Link>
        </div> 
        </td>
        </tr>
        </tbody>
    </table> <br></br>

    </div>

    </div>

    <div className="row">
    <Tabel data={this.state.data} load={this.state.loadtable}></Tabel>    
    </div>
                </Fragment>
            )
        }else{ return (<Fragment> <Loading></Loading> </Fragment>) }
    }
}

const mapStateToProps = (state) => {
    return {
        loggedin : state.Loggedin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_true : () => dispatch({type: 'SET_TRUE'}),
        set_false : () => dispatch({type: 'SET_FALSE'})
    }
 }

// const mapDispatchToProps = (dispatch) => {
//    return {
//        set_true : () => dispatch({type: ActionType.SET_TRUE}),
//        set_false : () => dispatch({type: ActionType.SET_FALSE})
//    }
// }

export default connect(mapStateToProps,mapDispatchToProps)(Log);